
import React from "react"

import OnsorderPage from "../../components/negosyonow/controls/onsordereditor";

const AllOrderPage = ({location}) => {
	return <OnsorderPage
				location={location}
				methodid={0}
			/>
}


export default AllOrderPage;


import React, {useState} from "react"

import Layout from "../../layout"
import WebappsForm from "../../webapps/form"

const formatTools = require("../../../../lib/formatTools");
const negosyonowAPI = require("../../../../lib/requestNegosyownow")
const webappsAPI = require("../../../../lib/requestWebapps");

const ENTITYID="onsorderform"

const searchFields = [
	{"label":"Order Date", "dbfield": "onsorderform_datetime", "type": "datetime", "filtertype": "datetime"},
	{"label":"Order #", "dbfield": "onsorderform_code", "type": "text", "filtertype": "textbox"},
	{"label":"NegosyoNow #", "dbfield": "onsorderform_channelref", "type": "text", "filtertype": "textbox"},
	{"label":"Handler", "dbfield": "nnpickup_name", "type": "text", "filtertype": "textbox"},
	{"label":"Details", "dbfield": "onsorderform_fulfillmentinfo", "type": "text", "filtertype": "textbox"},
	{"label":"Customer", "dbfield": "onscustomer_name", "type": "text", "filtertype": "textbox"},
	{"label":"Status", "dbfield": "onsorderformstatus_name", "type": "text", "filtertype": "textbox"},
	{"label":"Confirmed", "dbfield": "onsorderform_customerconfirm", "type": "checkbox", "filtertype": "checkbox"},
	{"label":"Amount", "dbfield": "onsorderform_totalamount", "type": "currency", "filtertype": "currency"},
	{"label":"PCs", "dbfield": "onsorderform_totalpcs", "type": "integer", "filtertype": "integer"},
	{"label":"Updated", "dbfield": "onsorderform_updatetime", "type": "datetime", "filtertype": "datetime"}
];

const formFields = [
	[
		{"label":"Order Date", "field": "onsorderform_datetime", "value": "", "input": "datetime", "mode": "readonly"},
		{"label":"Order #", "field": "onsorderform_code", "value": "", "input": "textbox", "mode": "readonly"},
		{"label":"Target Date", "field": "onsorderform_targetdate", "value": "", "input": "date", "mode": "readonly"},

		{"label":"Status", "field": "onsorderformstatus_id", "value": "", "input": "combo", "mode": "required", "options": [
			{"value":1,"display":"Draft"},
			{"value":2,"display":"Ordered"},
			{"value":3,"display":"Prepared"},
			{"value":4,"display":"For Shipping"},
			{"value":5,"display":"Completed"},	// Delivered
			{"value":6,"display":"Cancelled"},
		]},
		{"label":"Status Notes", "field": "onsorderform_tmpnotes", "value": "", "input": "textarea", "mode": "normal"},


		{"label":"Last Updated", "field": "onsorderform_updatetime", "value": "", "input": "datetime", "mode": "readonly"},


		{"label":"Customer", "field": "onscustomer_name", "value": "", "input": "picker", "mode": "readonly"},
		{"label":"Handler", "field": "nnpickup_name", "value": "", "input": "picker", "mode": "readonly"},
		{"label":"Details", "field": "onsorderform_fulfillmentinfo", "value": "", "input": "textrarea", "mode": "readonly"},

		{"label":"Confirmed", "field": "onsorderform_customerconfirm", "value": "", "input": "checkbox", "mode": "readonly"},


	]
];


const subformFields = [
	{
		"subformid": "item",
		"label": "Items",
		"table": "onsbookingitem",
		"sort": "onsbookingitem_id",
		"mobilerowtitlefieldidx":[2],
		"minrow": 1,
		"maxrow": 1,
		"allowdel": false,
		"fieldlist": [
			{
				"label": "Item",
				"field": "onsproduct_name",
				"subtotalid": "",
				"value": "",
				"input": "picker",
				"mode": "readonly"
			},
			{
				"label": "Active",
				"field": "onsbookingitem_active",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "required"
			},
			{
				"label": "Product Org",
				"field": "onsproduct_orgid",
				"subtotalid": "",
				"value": "",
				"input": "hidden",
				"mode": "readonly"
			},
			{
				"label": "Product Org SKU",
				"field": "onsproduct_code",
				"subtotalid": "",
				"value": "",
				"input": "hidden",
				"mode": "readonly"
			},
			{
				"label": "Product Active",
				"field": "onsproduct_active",
				"subtotalid": "",
				"value": "",
				"input": "hidden",
				"mode": "readonly"
			},
			{
				"label": "Price/Unit",
				"field": "#onsproduct_price",
				"subtotalid": "",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			},
			{
				"label": "Order QTY",
				"field": "onsbookingitem_qty",
				"subtotalid": "orderpcs",
				"destfield": "onsorderform_totalpcs",
				"value": "",
				"input": "integer",
				"mode": "required"
			},
			{
				"label": "Order Subtotal",
				"field": "onsbookingitem_qty*onsproduct_price*onsbookingitem_active",
				"subtotalid": "orderamount",
				"value": "",
				"input": "currency",
				"mode": "required"
			},
			{
				"label": "Available QTY",
				"field": "onsbookingitem_pickqty",
				"subtotalid": "availableorderpcs",
				"value": "",
				"input": "integer",
				"mode": "required"
			},
			{
				"label": "Available Subtotal",
				"field": "onsbookingitem_pickqty*onsproduct_price*onsbookingitem_active",
				"subtotalid": "availableorderamount",
				"value": "",
				"input": "currency",
				"mode": "required"
			},
			{
				"label": "Served QTY",
				"field": "onsbookingitem_deliverqty",
				"subtotalid": "deliverorderpcs",
				"value": "",
				"input": "integer",
				"mode": "required"
			},
			{
				"label": "Served Subtotal",
				"field": "onsbookingitem_deliverqty*onsproduct_price*onsbookingitem_active",
				"subtotalid": "deliverorderamount",
				"value": "",
				"input": "currency",
				"mode": "required"
			},
			{
				"label": "Notes",
				"field": "onsbookingitem_notes",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			},
		],
		"footerlist": [
			{
				"label": "Fees",
				"field": "onsorderform_fulfillmentamount",
				"subtotalid": "",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			},
			{
				"label": "Ordered Total",
				"field": "onsorderform_fulfillmentamount+orderamount",
				"subtotalid": "",
				"destfield": "onsorderform_totalamount",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			},
			{
				"label": "Available Total",
				"field": "onsorderform_fulfillmentamount+availableorderamount",
				"subtotalid": "",
				"destfield": "onsorderform_availableamount",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			},
			{
				"label": "Served Total",
				"field": "onsorderform_fulfillmentamount+deliverorderamount",
				"subtotalid": "",
				"destfield": "onsorderform_deliveramount",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			},
		]
	}
];

const newformFields = [
];

const newSubformFields = [
];

const OnsorderPage = ({location, methodid}) => {
	const [userinfo, setUserinfo] = useState({});
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");
	const [docstatusid, setDocstatusid] = useState(0);

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		//var customparam = {};
		const updatetimestr = formatTools.getDateStr();
		var encodernotes = "";
		var customparam = JSON.parse(JSON.stringify(params));
		var newstatusid = docstatusid;

		/*
		var pkid = 0;
		if (params.hasOwnProperty("documentid")) {
			pkid = params.documentid;
		}
		*/

		customparam.onsorderform_updatetime = updatetimestr;
		if (params.hasOwnProperty("onsorderform_tmpnotes")) {
			if (params.onsorderform_tmpnotes) {
				encodernotes = params.onsorderform_tmpnotes;
			}
			delete customparam.onsorderform_tmpnotes;
		}
		if (params.hasOwnProperty("onsorderformstatus_id")) {
			if (params.onsorderformstatus_id) {
				newstatusid = params.onsorderformstatus_id;
			}
		}

		//callback({"status":"Error"}); return;

		webappsAPI.savePayload(entity, customparam, token, payloadlist, subformpayloadlist, function(response){
			if (response.status === "OK") {
				updateSupportTicket(response.documentid, token, updatetimestr, parseInt(newstatusid, 10), encodernotes, function(result) {
					triggerNotifications(response.documentid, result, token, function() {
						callback(response);
					});
				});
			} else {
				callback(response);
			}
		});
	}

	function triggerNotifications(pkid, result, token, callback)
	{
		negosyonowAPI.buildOrder(token, "order",{
			"notifysubject": result,
			"orderform": pkid
		}).then(nnresponse => {
			callback();
		});
	}

	function updateSupportTicket(pkid, token, updatetimestr, statusid, encodernotes, callback)
	{
		var onssupportticketstatus_id = (statusid>=5)?5:2; // 5: closed, 2: Active

		// TODO: ONSCONTACT and ONSADDRESS causing SQL join error if none
		const apiparam = {
			"dbfieldlist":["onssupportticketstatus_id", "onssupportticket_id",
							//"onscontact_info", "onsaddress_label",
							"onssupportticketchannel_id"],
			"filters":[[{
					field: "onsorderform_id",
					operation: "=",
					value: pkid
				}]]
		};
		var result = "Ordered";

		if (statusid === 1) {
			onssupportticketstatus_id = 1;
			result = "Draft";
		} else if (statusid === 5) {
			result = "Completed";
		} else if (statusid === 6) {
			result = "Cancelled";
		} else if (statusid === 3) {
			result = "Prepared";
		} else {
			result = "Ordered";
		}

		webappsAPI.loadData("onssupportticket", apiparam, token).then(dataresponse => {
			//userinfo.name
			var supportticketobj = {};
			if (dataresponse.hasOwnProperty("data")) {
				if (dataresponse.data.length > 0) {
					supportticketobj = dataresponse.data[0];
				}
			}
			if (!supportticketobj.hasOwnProperty("onssupportticket_id")) {
				callback("");
				return;
			}
			var updateobj = {
				"documentid":supportticketobj.onssupportticket_id,
				"onssupportticketstatus_id": onssupportticketstatus_id,
				"onssupportticket_dateupdated":updatetimestr,
				"onssupportticket_result":result,
				"onssupportticket_encoder":userinfo.name
			};
			if (onssupportticketstatus_id === 5) {
				updateobj.onssupportticket_dateclosed = updatetimestr;
			}
			webappsAPI.saveRecord("ONSSUPPORTTICKET", updateobj, token).then(response => {
				updateobj = {
					"onssupportticket_id":supportticketobj.onssupportticket_id,
					"onssupportticketchannel_id":supportticketobj.onssupportticketchannel_id,
					//"onscontact_id":supportticketobj.onscontact_id,
					//"onsaddress_id":supportticketobj.onsaddress_id,
					"onssupportticketstatus_id": onssupportticketstatus_id,
					"onssupporttickethistory_date":updatetimestr,
					"onssupporttickethistory_encoder":userinfo.name,
					"onssupporttickethistory_resultdate":updatetimestr
				};
				if (onssupportticketstatus_id !== 5) {
					if (supportticketobj.onssupportticketstatus_id === onssupportticketstatus_id) {
						result = "Updated";
					}
				} else {
					encodernotes = result+"; "+encodernotes;
				}
				updateobj.onssupporttickethistory_result = result;
				updateobj.onssupporttickethistory_notes = encodernotes;

				webappsAPI.saveRecord("ONSSUPPORTTICKETHISTORY", updateobj, token).then(historyresponse => {
					callback(result);
				}); // add history

			}); // update main ticket

		}); // Load existing
	} // updateSupportTicket

	function customSetFormData(newformdata)
	{
		var newstatusid = 0;
		if (newformdata.hasOwnProperty("onsorderformstatus_id")) {
			if (newformdata.onsorderformstatus_id) {
				newstatusid = newformdata.onsorderformstatus_id;
			}
		}
		setDocstatusid(newstatusid);
	}


	function findSubformIdxByTablename(tablename)
	{
		var subformidx = 0;
		while (subformidx < subformFields.length) {
			if (subformFields[subformidx].table === tablename) {
				return subformidx;
			}
			subformidx++;
		}
		return -1;
	}

	function subformValidateQTYValues(newstatusid, itemsubform)
	{
		const COMPLETEDSTATUSID=5;
		var rowidx = 0;
		var colidx = 0;
		var totalfound = 0;
		var nosupplierdatafound = 0;
		var isactive = false;
		while (rowidx < itemsubform.length) {
			isactive = false;
			colidx = 0;
			while (colidx < itemsubform[rowidx].length) {
				if (itemsubform[rowidx][colidx].field === "onsbookingitem_active") {
					if (parseInt(itemsubform[rowidx][colidx].value,10)>0) {
						isactive = true;
					}
				}
				colidx++;
			}
			if (isactive) {
				colidx = 0;
				while (colidx < itemsubform[rowidx].length) {
					if (itemsubform[rowidx][colidx].field === "onsbookingitem_qty" || itemsubform[rowidx][colidx].field === "onsbookingitem_pickqty" || itemsubform[rowidx][colidx].field === "onsbookingitem_deliverqty") {
						if (parseInt(itemsubform[rowidx][colidx].value,10) < 0) {
							return {"status":"Error", "message":"Invalid QTY Value"};
						}
						if (itemsubform[rowidx][colidx].field === "onsbookingitem_deliverqty") {
							totalfound = totalfound + parseInt(itemsubform[rowidx][colidx].value,10);
							if (newstatusid !== COMPLETEDSTATUSID && totalfound>0) {
								return {"status":"Error", "message":"Please set Completed QTY to 0 while document is not being completed"};
							}
						}
					} else if (itemsubform[rowidx][colidx].field === "onsproduct_orgid") {
						if (parseInt(itemsubform[rowidx][colidx].value,10) <= 1) {
							nosupplierdatafound++;
						}

					} else if (itemsubform[rowidx][colidx].field === "onsproduct_code") {
						if ((""+itemsubform[rowidx][colidx].value).length < 1) {
							nosupplierdatafound++;
						}
					}
					colidx++;
				}
			}

			rowidx++;
		}
		if (totalfound === 0 && newstatusid === COMPLETEDSTATUSID) {
			return {"status":"Error", "message":"No Completed QTY found"}
		} else if (nosupplierdatafound > 0 && newstatusid > 1) {
			// Not draft
			return {"status":"Error", "message":"Item(s) with no supplier/SKU code"}
		}
		return {"status":"OK"};
	}

	function formValidateValues(mainform, subformlist, subformfooterlist)
	{
		var newstatusid  = 0;
		var tmpnotes = "";
		var idx = 0;
		while (idx < mainform.length) {
			if (mainform[idx].field === "onsorderformstatus_id") {
				newstatusid = parseInt(mainform[idx].value,10);
			} else if (mainform[idx].field === "onsorderform_tmpnotes") {
				tmpnotes = mainform[idx].value;
			}
			idx++;
		}
		if (newstatusid === 6 && tmpnotes.length < 1) {
			// Cancelled
			return {"status":"Error", "message":"Please specify Cancel Reason"}
		}
		var subformidx = findSubformIdxByTablename("onsbookingitem");
		if (subformidx>=0) {
			var tmpres = subformValidateQTYValues(newstatusid, subformlist[subformidx]);
			if (tmpres.status !== "OK") {
				return tmpres;
			}
		}

		return {"status":"OK"};
	}

	function customEditFieldInfo(inputlist, idx)
	{
		var tmpfield = {};
		if (inputlist[idx].field === "onsorderformstatus_id") {
			tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
			if (docstatusid === 1) {
				tmpfield.options = [
					{"value":1,"display":"Draft"},
					{"value":2,"display":"Ordered"},
					{"value":6,"display":"Cancelled"},
				];
			} else if (docstatusid === 2) {
					tmpfield.options = [
						{"value":2,"display":"Ordered"},
						{"value":5,"display":"Completed"},	// Delivered
						{"value":6,"display":"Cancelled"},
					];
			} else {
				tmpfield.mode = "readonly";
				/*
					tmpfield.options = [
						{"value":1,"display":"Draft"},
						{"value":2,"display":"Ordered"},
						{"value":3,"display":"Prepared"},
						{"value":4,"display":"For Shipping"},
						{"value":5,"display":"Completed"},	// Delivered
						{"value":6,"display":"Cancelled"},
					];
				*/
			}
			return tmpfield;
		} else if (inputlist[idx].field === "onsorderform_tmpnotes") {
			if (docstatusid >= 5) {
				tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
				tmpfield.mode = "readonly";
				return tmpfield;
			}
		} else if (inputlist[idx].field === "onsbookingitem_qty") {
			if (docstatusid > 1) {
				tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
				tmpfield.mode = "readonly";
				return tmpfield;
			}
		} else if (inputlist[idx].field === "onsbookingitem_pickqty" || inputlist[idx].field === "onsbookingitem_deliverqty") {
			if (docstatusid !== 2) {
				tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
				tmpfield.mode = "readonly";
				return tmpfield;
			}
		} else if (inputlist[idx].field === "onsbookingitem_active" || inputlist[idx].field === "onsbookingitem_notes") {
			if (docstatusid >= 5) {
				tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
				tmpfield.mode = "readonly";
				return tmpfield;
			} else if (inputlist[idx].field === "onsbookingitem_notes") {
				var warningidx = 0;
				var softwarningtext = "";
				var warningtext = "";
				while (warningidx < inputlist.length) {
					if (inputlist[warningidx].field === "onsproduct_active") {
						if (parseInt(inputlist[warningidx].value, 10) === 0) {
							softwarningtext = softwarningtext + ";Item Marked Unavailable";
						}
					} else if (inputlist[warningidx].field === "onsproduct_orgid") {
						if (parseInt(inputlist[warningidx].value, 10) <= 1) {
							warningtext = warningtext + ";No Supplier";
						}

					} else if (inputlist[warningidx].field === "onsproduct_code") {
						if ((""+inputlist[warningidx].value).length < 1) {
							warningtext = warningtext + ";No SKU Code";
						}
					}
					warningidx++;
				} // while inputlist
				if (warningtext.length > 0 || softwarningtext.length > 0) {
					var dividerchar = "";
					tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
					if (!tmpfield.hasOwnProperty("info")) {
						tmpfield.info = "";
					} else if (!tmpfield.info) {
						tmpfield.info = "";
					} else {
						dividerchar = ";";
					}
					if (softwarningtext.length > 0) {
						tmpfield.info = tmpfield.info+dividerchar+softwarningtext.substring(1);
						dividerchar = ";";
					}
					if (warningtext.length > 0) {
						tmpfield.info = tmpfield.info+dividerchar+"WARNING:"+warningtext.substring(1);
					}
					return tmpfield;
				}
			}
		}
		return inputlist[idx];

	}

	return <Layout fullPath={location.pathname}
			newUserHandler={(newtoken, newuserinfo)=>{setToken(newtoken); setUserinfo(newuserinfo)}}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			privatePage={true} usegatedcontent={true}>
			<WebappsForm
				location={location}
				allowadd={false}
				allowdelete={false}
				pagetitle={pagetitle}
				mobilerowtitlefield={["onsorderform_code","onscustomer_name"]}
				userSearchParam={{"orderby":"onsorderform_datetime desc"}}
				userSearchFilter={methodid>0?[{"field":"onsorderformfulfillment_id", "operation":"=", "value": methodid}]:[]}
				customSubmit={customSubmit}
				customSetFormData={customSetFormData}
				searchFields={searchFields}
				customEditFieldInfo={customEditFieldInfo}
				editFields={formFields}
				newformFields={newformFields}
				editSubFormFields={subformFields}
				newSubformFields={newSubformFields}
				customValidateValues={formValidateValues}
				entity={ENTITYID}
				token={token} />
		</Layout>

}


export default OnsorderPage;
